import { Payment } from "API/Generated/Payment";
import { PaymentTypes } from "API/Generated/PaymentTypes";
import { PaymentStatuses } from "API/Generated/PaymentStatuses";
import { PaymentStatusModel, PaymentTypeModel } from "API/Generated/data-contracts";
import { makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { RequestStatus } from "SessionState/RequestHandling/RequestStatus";

interface Request {
    id: number;
    status: RequestStatus;
}

class PaymentStore {
    Payment: Payment = new Payment();
    PaymentTypeService: PaymentTypes = new PaymentTypes();
    PaymentStatusService: PaymentStatuses = new PaymentStatuses();
    

    PaymentTypes: PaymentTypeModel[];
    PaymentStatuses: PaymentStatusModel[];

    PaymentTypeRequset: Request = {
        id: 0,
        status: RequestStatus.PENDING
    };

    constructor(currentPaymentTypes: PaymentTypeModel[], currentPaymentStatuses: PaymentStatusModel[], currentPageantRequest: Request) {
        makeObservable(this, {
            PaymentTypes: observable,
            PaymentStatuses: observable,
            PaymentTypeRequset: observable
        })
        makePersistable(this, { name: 'PaymentStore', properties: ['PaymentTypes', 'PaymentStatuses'], storage: window.localStorage});

        this.PaymentTypes = currentPaymentTypes;
        this.PaymentStatuses = currentPaymentStatuses;
        this.PaymentTypeRequset = currentPageantRequest;
    }

    async getPaymentTypes() {
        if (this.PaymentTypes.length != 0 || this.PaymentTypeRequset.status == RequestStatus.COMPLETED || this.PaymentTypeRequset?.status == RequestStatus.ACTIVE ) return;
        try {
            this.PaymentTypeRequset.status = RequestStatus.ACTIVE;
            var paymentResult = await this.PaymentTypeService.getPaymentTypes();
            if (paymentResult.data.data) {
                this.PaymentTypes = paymentResult?.data.data;
                this.PaymentTypeRequset.status = RequestStatus.COMPLETED;
            }
        }
        catch (error) {
            this.PaymentTypeRequset.status = RequestStatus.ERROR;
            console.log("Errror: " + JSON.stringify(error))
        }
    }

    async getPaymentStatuses() {
        if (this.PaymentStatuses.length != 0) return;
        try {
            var paymentResult = await this.PaymentStatusService.getPaymentStatuses();
            if (paymentResult.data.data) {
                this.PaymentStatuses = paymentResult?.data.data;
            }
        }
        catch (error) {
            console.log("Errror: " + JSON.stringify(error))
        }
    }

    async approvePayment(paymentId: number) {
        try {
            var paymentResult = await this.Payment.approvePayment(paymentId);
            if (paymentResult.data.data) {
                return paymentResult.data.data;
            }
            return paymentResult.data.data;
        }
        catch (error) {
            console.log("Errror: " + JSON.stringify(error))
            return false;
        }
    }
    async declinePayment(paymentId: number) {
        try {
            var paymentResult = await this.Payment.denyPayment(paymentId);
            if (paymentResult.data.data) {
                return paymentResult.data.data;
            }
            return paymentResult.data.data;
        }
        catch (error) {
            console.log("Errror: " + JSON.stringify(error))
            return false;
        }
    }

}

let paymentStore: PaymentStore;

export function GetPaymentStore() {
    if (!paymentStore) {
        paymentStore = new PaymentStore([], [], { id: 0, status: RequestStatus.PENDING });
    }
    return paymentStore
}