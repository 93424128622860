/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BooleanResponseWrapper, DirectorUserBaseModel, DirectorUserModel, DirectorUserModelResponseWrapper } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class DirectorUsers<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags DirectorUser
   * @name GetByDirectorUserId
   * @request GET:/DirectorUsers/{directorUserId}
   */
  getByDirectorUserId = (directorUserId: number, params: RequestParams = {}) =>
    this.request<DirectorUserModel, any>({
      path: `/DirectorUsers/${directorUserId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags DirectorUser
   * @name SaveDirectorUser
   * @request POST:/DirectorUsers/Save
   */
  saveDirectorUser = (data: DirectorUserModel, params: RequestParams = {}) =>
    this.request<DirectorUserModelResponseWrapper, any>({
      path: `/DirectorUsers/Save`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags DirectorUser
   * @name AddDirectorUser
   * @request POST:/DirectorUsers
   */
  addDirectorUser = (data: DirectorUserBaseModel, params: RequestParams = {}) =>
    this.request<DirectorUserModelResponseWrapper, any>({
      path: `/DirectorUsers`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags DirectorUser
   * @name PatchDirectorUser
   * @request PATCH:/DirectorUsers
   */
  patchDirectorUser = (data: DirectorUserBaseModel, params: RequestParams = {}) =>
    this.request<DirectorUserModelResponseWrapper, any>({
      path: `/DirectorUsers`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags DirectorUser
   * @name DeleteDirectorUser
   * @request DELETE:/DirectorUsers
   */
  deleteDirectorUser = (
    query?: {
      /** @format int32 */
      directorUserId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/DirectorUsers`,
      method: 'DELETE',
      query: query,
      format: 'json',
      ...params,
    });
}
