import React, { memo } from 'react';
import { Route, Redirect, generatePath } from 'react-router-dom';
import { DEFAULT_PATHS } from 'config.js';

const RouteItem = ({ redirect = false, exact = false, path, to, component }) => {
  if (!path && !to) {
    return () => {};
  }
  if (redirect) {
    const props = {};
    // console.log('redirect', path, to);
    if (path) props.from = path;
    if (exact) props.exact = exact;
    if (to) props.to = to;
    // console.log('props', props);
    return <Route path={path} exact={exact} render={({match}) => {
    if (match.params.id != null && match.params.id != undefined && match.params.name != null && match.params.name != undefined) {
      return <Redirect to={{pathname: generatePath(to.pathname, match.params), state: {path}}} />  
    }
    return <Redirect {...props} />  
    }}
    />;
    // return <Redirect {...props} />;
  }
  const props = {};
  if (path) props.path = path;
  if (exact) props.exact = exact;
  if (component) props.component = component;

  return <Route {...props} />;
};

export default memo(RouteItem);
