/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BooleanResponseWrapper, IndustryStyleTypeBaseModel, IndustryStyleTypeModel, IndustryStyleTypeModelResponseWrapper } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class IndustryStyleTypes<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags IndustryStyleType
   * @name GetByIndustryStyleTypeId
   * @request GET:/IndustryStyleTypes/{industryStyleTypeId}
   */
  getByIndustryStyleTypeId = (industryStyleTypeId: number, params: RequestParams = {}) =>
    this.request<IndustryStyleTypeModel, any>({
      path: `/IndustryStyleTypes/${industryStyleTypeId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IndustryStyleType
   * @name GetAllIndustryStyleTypes
   * @request GET:/IndustryStyleTypes
   */
  getAllIndustryStyleTypes = (params: RequestParams = {}) =>
    this.request<IndustryStyleTypeModel[], any>({
      path: `/IndustryStyleTypes`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IndustryStyleType
   * @name AddIndustryStyleType
   * @request POST:/IndustryStyleTypes
   */
  addIndustryStyleType = (data: IndustryStyleTypeBaseModel, params: RequestParams = {}) =>
    this.request<IndustryStyleTypeModelResponseWrapper, any>({
      path: `/IndustryStyleTypes`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IndustryStyleType
   * @name PatchIndustryStyleType
   * @request PATCH:/IndustryStyleTypes
   */
  patchIndustryStyleType = (data: IndustryStyleTypeBaseModel, params: RequestParams = {}) =>
    this.request<IndustryStyleTypeModelResponseWrapper, any>({
      path: `/IndustryStyleTypes`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IndustryStyleType
   * @name DeleteIndustryStyleType
   * @request DELETE:/IndustryStyleTypes
   */
  deleteIndustryStyleType = (
    query?: {
      /** @format int32 */
      industryStyleTypeId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/IndustryStyleTypes`,
      method: 'DELETE',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IndustryStyleType
   * @name SaveIndustryStyleType
   * @request POST:/IndustryStyleTypes/Save
   */
  saveIndustryStyleType = (data: IndustryStyleTypeModel, params: RequestParams = {}) =>
    this.request<IndustryStyleTypeModelResponseWrapper, any>({
      path: `/IndustryStyleTypes/Save`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
