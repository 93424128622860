/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BooleanResponseWrapper, PageantRegistrationRequest, PictureModel, RegisteredPageantContestantModelResponseWrapper } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PageantRegistration<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags PageantRegistration
   * @name GetPageantRegistrationById
   * @request GET:/PageantRegistration/{pageantRegistrationId}
   */
  getPageantRegistrationById = (pageantRegistrationId: number, params: RequestParams = {}) =>
    this.request<RegisteredPageantContestantModelResponseWrapper, any>({
      path: `/PageantRegistration/${pageantRegistrationId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PageantRegistration
   * @name DeletePageantRegistration
   * @request DELETE:/PageantRegistration/{pageantRegistrationId}
   */
  deletePageantRegistration = (pageantRegistrationId: number, params: RequestParams = {}) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/PageantRegistration/${pageantRegistrationId}`,
      method: 'DELETE',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PageantRegistration
   * @name AddPageantRegistration
   * @request POST:/PageantRegistration
   */
  addPageantRegistration = (data: PageantRegistrationRequest, params: RequestParams = {}) =>
    this.request<RegisteredPageantContestantModelResponseWrapper, any>({
      path: `/PageantRegistration`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PageantRegistration
   * @name PatchPageantRegistration
   * @request PATCH:/PageantRegistration
   */
  patchPageantRegistration = (data: PageantRegistrationRequest, params: RequestParams = {}) =>
    this.request<RegisteredPageantContestantModelResponseWrapper, any>({
      path: `/PageantRegistration`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PageantRegistration
   * @name PatchPageantRegistrationResultsVisibility
   * @request PATCH:/PageantRegistration/saveResultVisibility
   */
  patchPageantRegistrationResultsVisibility = (
    query?: {
      /** @format int32 */
      pageantRegistrationId?: number;
      isResultsPrivate?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.request<RegisteredPageantContestantModelResponseWrapper, any>({
      path: `/PageantRegistration/saveResultVisibility`,
      method: 'PATCH',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PageantRegistration
   * @name PatchPageantRegistrationHeadShot
   * @request PATCH:/PageantRegistration/saveHeadShot
   */
  patchPageantRegistrationHeadShot = (
    data: PictureModel,
    query?: {
      /** @format int32 */
      pageantRegistrationId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<RegisteredPageantContestantModelResponseWrapper, any>({
      path: `/PageantRegistration/saveHeadShot`,
      method: 'PATCH',
      query: query,
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
