/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BusinessTypeBaseModel } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class BusinessType<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags BusinessType
   * @name BusinessTypesList
   * @request GET:/BusinessType/BusinessTypes
   */
  businessTypesList = (params: RequestParams = {}) =>
    this.request<BusinessTypeBaseModel[], any>({
      path: `/BusinessType/BusinessTypes`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags BusinessType
   * @name CreateBusinessTypeCreate
   * @request POST:/BusinessType/CreateBusinessType
   */
  createBusinessTypeCreate = (data: BusinessTypeBaseModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/BusinessType/CreateBusinessType`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags BusinessType
   * @name DeleteBusinessTypeDelete
   * @request DELETE:/BusinessType/DeleteBusinessType
   */
  deleteBusinessTypeDelete = (
    query?: {
      /** @format int32 */
      businessTypeId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/BusinessType/DeleteBusinessType`,
      method: 'DELETE',
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags BusinessType
   * @name UpdateBusinessTypeUpdate
   * @request PUT:/BusinessType/UpdateBusinessType
   */
  updateBusinessTypeUpdate = (data: BusinessTypeBaseModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/BusinessType/UpdateBusinessType`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
