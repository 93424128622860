/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContestantPublicInfoBaseModel, ContestantUserBaseModel } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class ContestantUsersBasic<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ContestantUser
   * @name GetContestantUserPublicInfoByContestantId
   * @request GET:/ContestantUsersBasic/PublicInfo/{contestantUserId}
   */
  getContestantUserPublicInfoByContestantId = (contestantUserId: number, params: RequestParams = {}) =>
    this.request<ContestantPublicInfoBaseModel, any>({
      path: `/ContestantUsersBasic/PublicInfo/${contestantUserId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ContestantUser
   * @name GetAllContestantUsersBasicInfo
   * @request GET:/ContestantUsersBasic
   */
  getAllContestantUsersBasicInfo = (params: RequestParams = {}) =>
    this.request<ContestantUserBaseModel[], any>({
      path: `/ContestantUsersBasic`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ContestantUser
   * @name GetAllContestantUsersBasicPublicInfo
   * @request GET:/ContestantUsersBasic/PublicInfo
   */
  getAllContestantUsersBasicPublicInfo = (params: RequestParams = {}) =>
    this.request<ContestantPublicInfoBaseModel[], any>({
      path: `/ContestantUsersBasic/PublicInfo`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ContestantUser
   * @name GetAllRecentWinnersBasicPublicInfo
   * @request GET:/ContestantUsersBasic/RecentWinners
   */
  getAllRecentWinnersBasicPublicInfo = (params: RequestParams = {}) =>
    this.request<ContestantPublicInfoBaseModel[], any>({
      path: `/ContestantUsersBasic/RecentWinners`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
