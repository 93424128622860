/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BooleanResponseWrapper, UserBaseModel, UserModel, UserModelResponseWrapper } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Users<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags User
   * @name GetByUserId
   * @request GET:/Users/{userId}
   */
  getByUserId = (userId: number, params: RequestParams = {}) =>
    this.request<UserModel, any>({
      path: `/Users/${userId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name GetBasicInfoByUserId
   * @request GET:/Users/BasicInfo/{userId}
   */
  getBasicInfoByUserId = (userId: number, params: RequestParams = {}) =>
    this.request<UserBaseModel, any>({
      path: `/Users/BasicInfo/${userId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name GetByUsername
   * @request GET:/Users/{username}
   */
  getByUsername = (username: string, params: RequestParams = {}) =>
    this.request<UserModel, any>({
      path: `/Users/${username}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name GetAllUsers
   * @request GET:/Users
   */
  getAllUsers = (params: RequestParams = {}) =>
    this.request<UserBaseModel[], any>({
      path: `/Users`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name AddUser
   * @request POST:/Users
   */
  addUser = (data: UserBaseModel, params: RequestParams = {}) =>
    this.request<UserModelResponseWrapper, any>({
      path: `/Users`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name PatchUser
   * @request PATCH:/Users
   */
  patchUser = (data: UserBaseModel, params: RequestParams = {}) =>
    this.request<UserModelResponseWrapper, any>({
      path: `/Users`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name DeleteUser
   * @request DELETE:/Users
   */
  deleteUser = (
    query?: {
      /** @format int32 */
      userId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/Users`,
      method: 'DELETE',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name GetAllCoachUsers
   * @request GET:/Users/Coach
   */
  getAllCoachUsers = (params: RequestParams = {}) =>
    this.request<UserBaseModel[], any>({
      path: `/Users/Coach`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name GetAllDirectorUsers
   * @request GET:/Users/Director
   */
  getAllDirectorUsers = (params: RequestParams = {}) =>
    this.request<UserBaseModel[], any>({
      path: `/Users/Director`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name GetAllVendorUsers
   * @request GET:/Users/Vendors
   */
  getAllVendorUsers = (params: RequestParams = {}) =>
    this.request<UserBaseModel[], any>({
      path: `/Users/Vendors`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name SaveUser
   * @request POST:/Users/Save
   */
  saveUser = (data: UserModel, params: RequestParams = {}) =>
    this.request<UserModelResponseWrapper, any>({
      path: `/Users/Save`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name SaveUserWithPassword
   * @request POST:/Users/SaveUserWithPassword
   */
  saveUserWithPassword = (data: UserModel, params: RequestParams = {}) =>
    this.request<UserModelResponseWrapper, any>({
      path: `/Users/SaveUserWithPassword`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
