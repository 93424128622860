import { PageantRegistration } from "API/Generated/PageantRegistration";
import { PageantRegistrationRequest, PictureModel, RegisteredPageantContestantModel } from "API/Generated/data-contracts";
import { PageantAgeGroupBaseModel } from "helpers/PageantHelper";
import { makeObservable, observable, action } from "mobx";
import { makePersistable } from "mobx-persist-store";



class PageantRegistrationStore {
  
    PageantRegistration: PageantRegistration = new PageantRegistration();
    CurrentPageantRegistration: RegisteredPageantContestantModel;
    ShowRegistrationForm: boolean = false;
    AllowEdit: boolean = false;
    AgeGroups: PageantAgeGroupBaseModel[] = [];
    SupremeGroups: PageantAgeGroupBaseModel[] = [];

    constructor(currentPageantRegistration: RegisteredPageantContestantModel, showRegistrationForm: boolean) {
        makeObservable(this, {
            CurrentPageantRegistration: observable,
            ShowRegistrationForm: observable,
            AllowEdit: observable,
            AgeGroups: observable,
            setAgeGroups: action,
            SupremeGroups: observable,
            setSupremeGroups: action,
            setAllowEdit: action
        })
        // makePersistable(this, { name: 'PageantRegistration', properties: ['CurrentPageantRegistration', 'ShowRegistrationForm', 'AllowEdit', 'AgeGroups', 'SupremeGroups']})
        this.CurrentPageantRegistration = currentPageantRegistration;
    }

    setAgeGroups(ageGroups: PageantAgeGroupBaseModel[]) {
        this.AgeGroups = ageGroups;
    }

    setSupremeGroups(supremeGroups: PageantAgeGroupBaseModel[]) {
        this.SupremeGroups = supremeGroups;
    }

    async savePageantRegistration(model: PageantRegistrationRequest): Promise<boolean> {
        try {
            if (model.contestantUserModel) {
                model.contestantUserModel.registeredPageantContestants = undefined;
            }
    
            const pageantRegistrationResult = model.pageantRegistrationModel?.id && model.pageantRegistrationModel?.id > 0
                ? await this.PageantRegistration.patchPageantRegistration(model)
                : await this.PageantRegistration.addPageantRegistration(model);
    
            const responseData = pageantRegistrationResult.data.data;
            return responseData !== null;
        }
        catch (error) {
            console.error("Error while saving pageant registration:", error);
            throw new Error("Failed to save pageant registration. Please try again.");
        }
    }

    async savePageantRegistrationResultsVisibility(pageantRegistrationId: number, isResultsPrivate: boolean): Promise<boolean> {
        try {
          // Check if the pageantRegistrationId is valid
          if (!pageantRegistrationId || pageantRegistrationId <= 0) {
            throw new Error("Invalid pageant registration ID.");
          }
    
          // Check if isResultsPrivate is a boolean
          if (typeof isResultsPrivate !== 'boolean') {
            throw new Error("Invalid value for isResultsPrivate.");
          }
    
          // Call the API method to save results visibility
          const response = await this.PageantRegistration.patchPageantRegistrationResultsVisibility({ pageantRegistrationId, isResultsPrivate });

          // Check the response and return a boolean based on success
          return response && response.data !== null;
        } catch (error) {
          console.error("Error while saving pageant registration:", error);
          throw new Error("Failed to save pageant registration. Please try again.");
        }
      }
    

      async savePageantRegistrationHeadShot(pageantRegistrationId: number, headShot: PictureModel): Promise<boolean> {
        try {
          // Check if the pageantRegistrationId is valid
          if (!pageantRegistrationId || pageantRegistrationId <= 0) {
            throw new Error("Invalid pageant registration ID.");
          }
      
          // Check if headShot is a valid PictureModel (optional, depending on your validation requirements)
          if (!headShot || typeof headShot !== 'object' || !headShot.image) {
            throw new Error("Invalid headShot object.");
          }
      
          // Call the API method to save headshot
          const response = await this.PageantRegistration.patchPageantRegistrationHeadShot(
            headShot,
            { pageantRegistrationId }
          );
      
          // Check the response and return a boolean based on success
          return response && response.data !== null;
        } catch (error) {
          console.error("Error while saving pageant registration:", error);
          throw new Error("Failed to save pageant registration. Please try again.");
        }
      }
      
      


    

    async deletePageantRegistration(id: number): Promise<boolean> {
        try {
            var pageantRegistrationResult = await this.PageantRegistration.deletePageantRegistration(id);
            if (pageantRegistrationResult.data.data) {
                return true;
            }
            return false;
        }
        catch (error) {
            console.log("Error: " + JSON.stringify(error));
            return false;
        }
    }

    async showRegistrationForm() {
        this.ShowRegistrationForm = true;
    }

    async hideRegistrationForm() {
        this.ShowRegistrationForm = false;
    }

    async setAllowEdit(allowEdit: boolean) {
        this.AllowEdit = allowEdit;
    }

    async getRegistration(id): Promise<RegisteredPageantContestantModel | undefined> {
        try {
            var registrationResult = await this.PageantRegistration.getPageantRegistrationById(id);
            return registrationResult?.data?.data;
        }
        catch (error) {
            console.log("Errror: " + JSON.stringify(error))
        }
    }


    async refreshCurrentRegistration() {
        if (this.CurrentPageantRegistration.id) {
            var result = await this.getRegistration(this.CurrentPageantRegistration.id);
            if (result) {
                this.CurrentPageantRegistration = result;
            }
        }
    }

}

let pageantRegistrationStore: PageantRegistrationStore;

export function GetPageantRegistrationStore(): PageantRegistrationStore {
    if (!pageantRegistrationStore) {
        pageantRegistrationStore = new PageantRegistrationStore({}, false);
    }
    return pageantRegistrationStore;
}