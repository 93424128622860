import { makeObservable, observable, action, computed } from "mobx";
import { StateModel } from "API/Generated/data-contracts";
import { Geography } from "API/Generated/Geography";
import { RequestStatus } from "SessionState/RequestHandling/RequestStatus";

interface Request {
    id: number;
    status: RequestStatus;
}

class GeoStore {
    @observable GeoService: Geography = new Geography();
    @observable StateList: StateModel[] = [];
    StateRequest: Request = {
        id: 0,
        status: RequestStatus.PENDING
    };

    constructor(stateList: StateModel[], stateRequest: Request) {
        // makeObservable(this, {
        //     StateList: observable,
        //     getStates: action, // Mark getStates as an action
        // });
        this.StateList = stateList;
        this.StateRequest = stateRequest;
    }

    async getStates() {
        try {
            if (this.StateList.length === 0 && this.StateRequest.status !== RequestStatus.ACTIVE) {
                this.StateRequest.status = RequestStatus.ACTIVE;
                var stateResult = await this.GeoService.getStates();
                this.StateList = stateResult?.data;
                this.StateRequest.status = RequestStatus.COMPLETED;
            }
        } catch (error) {
            console.log("Error:" + JSON.stringify(error));
        }
    }
}

let geoStore: GeoStore;

export function GetGeoStore() {
    if (!geoStore) {
        geoStore = new GeoStore([], { id: 0, status: RequestStatus.PENDING });
    }
    return geoStore;
}
