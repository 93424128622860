/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BooleanResponseWrapper, PaymentModelResponseWrapper } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Payment<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Payment
   * @name GetPaymentById
   * @request GET:/Payment/{paymentId}
   */
  getPaymentById = (paymentId: number, params: RequestParams = {}) =>
    this.request<PaymentModelResponseWrapper, any>({
      path: `/Payment/${paymentId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Payment
   * @name ApprovePayment
   * @request GET:/Payment/Approve/{paymentId}
   */
  approvePayment = (paymentId: number, params: RequestParams = {}) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/Payment/Approve/${paymentId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Payment
   * @name DenyPayment
   * @request GET:/Payment/Deny/{paymentId}
   */
  denyPayment = (paymentId: number, params: RequestParams = {}) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/Payment/Deny/${paymentId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
