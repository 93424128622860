/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BooleanResponseWrapper, LogModel } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Logs<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Log
   * @name AddLog
   * @request POST:/Logs
   */
  addLog = (data: LogModel, params: RequestParams = {}) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/Logs`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Log
   * @name GetAllLogs
   * @request GET:/Logs
   */
  getAllLogs = (params: RequestParams = {}) =>
    this.request<LogModel[], any>({
      path: `/Logs`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Log
   * @name GetLogs
   * @request GET:/Logs/{startDate}/{endDate}
   */
  getLogs = (startDate: string, endDate: string, params: RequestParams = {}) =>
    this.request<LogModel[], any>({
      path: `/Logs/${startDate}/${endDate}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
