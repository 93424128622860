import { makeObservable, observable, action, computed, runInAction, makeAutoObservable } from "mobx"
import { UserModel } from '../../API/Generated/data-contracts'
import { Users } from '../../API/Generated/Users'
import { makePersistable } from "mobx-persist-store";
import { Account } from '../../API/Generated/Account';
import { RequestStatus } from "SessionState/RequestHandling/RequestStatus";

interface Request {
    id: number;
    status: RequestStatus;
}

export class UserStore {

    // Services
    UserService: Users = new Users();
    AccountService: Account = new Account();

    // Properties
    @observable CurrentUser: UserModel;
    @observable SelectedUser: UserModel | undefined;
    @observable UserList: UserModel[];
    @observable AllDirectorUsersList: UserModel[];
    @observable AllCoachUsersList: UserModel[];
    CurrentUserRequest: Request = {
        id: 0,
        status: RequestStatus.PENDING
    }
    GetUserByIdRequest?: Request = {
        id: 0,
        status: RequestStatus.PENDING
    }
    AllActiveDirectorsListRequest?: Request = {
        id: 0,
        status: RequestStatus.PENDING
    }
    

// Constructors
constructor(currentUser: UserModel, userList: UserModel[], allDirectorUsersList: UserModel[], allCoachUsersList: UserModel[], currentUserRequest: Request, selectedUser?: UserModel, getUserByIdRequest?: Request) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'UserStore', properties: ['CurrentUser'], storage: window.localStorage });

    this.CurrentUser = currentUser;
    this.UserList = userList;
    this.AllDirectorUsersList = allDirectorUsersList;
    this.AllCoachUsersList = allCoachUsersList;
    this.SelectedUser = selectedUser;
    this.CurrentUserRequest = currentUserRequest;
    this.GetUserByIdRequest = getUserByIdRequest;
}

    // Actions
    @action
    async setLoggedInUser() {
        try {
            var currentUser = await this.AccountService.currentUserList()
            // if (this.CurrentUserRequest.id != currentUser.data ||
            //     (this.CurrentUserRequest.id == currentUser.data && (this.CurrentUserRequest.status == RequestStatus.PENDING || this.CurrentUserRequest.status == RequestStatus.ERROR || this.CurrentUserRequest.status == RequestStatus.COMPLETED))) {
                this.CurrentUserRequest.id = currentUser.data;
                this.CurrentUserRequest.status = RequestStatus.ACTIVE
                var userResult = await this.UserService.getByUserId(currentUser.data);
                runInAction(() => {
                    console.log("Setting Logged In User", userResult);
                    this.CurrentUser = userResult?.data || {};
                    this.CurrentUserRequest.status = RequestStatus.COMPLETED
                });
            // }
        }
        catch (error) {
            // TODO: Call Log API endpoint
            console.log("Error: " + JSON.stringify(error));
            this.CurrentUserRequest.status = RequestStatus.ERROR
        }
    }

    @action
    async getUsers() {
        try {
            var userResult = await this.UserService.getAllUsers();
            this.UserList = userResult?.data;
        }
        catch (error) {
            console.log("Errror: " + JSON.stringify(error))
        }
    }
    

    @action
    async getAllDirectorUsers() {
        try {
            var directorResults = await this.UserService.getAllDirectorUsers()
            if (directorResults?.data) {
                this.AllDirectorUsersList = directorResults.data
            }
        }
        catch (error) {
            console.log("Error: " + JSON.stringify(error));
        }
    }

    // async getAllDirectorUsers(reset:boolean = false) {
    //     try {
    //         if (this?.AllActiveDirectorsListRequest?.status == RequestStatus.ACTIVE || this?.AllActiveDirectorsListRequest?.status == RequestStatus.COMPLETED
    //             && !reset) {
    //             return;
    //         }
    //         this.AllActiveDirectorsListRequest = { id: 0, status: RequestStatus.ACTIVE };
    //         var allActiveDirectorsListResult = await this.UserService.getAllDirectorUsers()
    //         // console.log("data", pageantResult);
    //         if (allActiveDirectorsListResult.data) {
    //             this.AllActiveDirectorsListRequest.status = RequestStatus.COMPLETED;
    //             this.AllDirectorUsersList = allActiveDirectorsListResult?.data;
    //         }
    //     } catch (error) {
    //         this?.AllActiveDirectorsListRequest?.status = RequestStatus.ERROR;
    //         console.log("Error:" + JSON.stringify(error));
    //     }
    // }

    @action
    async getAllCoachUsers() {
        try {
            var coachResults = await this.UserService.getAllCoachUsers()

            if (coachResults?.data) {
                this.AllCoachUsersList = coachResults.data
            }
        }
        catch (error) {
            console.log("Error: " + JSON.stringify(error));
        }
    }

    @action
    async getUser(id): Promise<UserModel | undefined> {
        try {
            if (this.GetUserByIdRequest?.id !== id || this.GetUserByIdRequest?.status !== RequestStatus.ACTIVE) {
                this.GetUserByIdRequest = {
                    id: id,
                    status: RequestStatus.ACTIVE
                };
    
                var userResult = await this.UserService.getByUserId(id);
                this.UserList = [userResult?.data]; // Assuming UserList is an observable array or object in your store
                this.GetUserByIdRequest.status = RequestStatus.COMPLETED;
            }
    
            return this.UserList[0]; // Assuming you only fetch one user
        }
        catch (error) {
            console.log("Error: " + JSON.stringify(error));
            this.GetUserByIdRequest = {
                id: id,
                status: RequestStatus.ERROR
            };
        }
    }

    @action
    async getUserBasicInfo(id): Promise<UserModel | undefined> {
        try {
            // if (this.GetUserByIdRequest?.id !== id || this.GetUserByIdRequest?.status !== RequestStatus.ACTIVE) {
            //     this.GetUserByIdRequest = {
            //         id: id,
            //         status: RequestStatus.ACTIVE
            //     };
    
                var userResult = await this.UserService.getBasicInfoByUserId(id);
                // this.UserList = [userResult?.data]; // Assuming UserList is an observable array or object in your store
                
                // this.GetUserByIdRequest.status = RequestStatus.COMPLETED;
                if (userResult.data) {
                    return userResult.data;    
                }
            // }
            return undefined;
            // return this.UserList[0]; // Assuming you only fetch one user
        }
        catch (error) {
            console.log("Error: " + JSON.stringify(error));
            this.GetUserByIdRequest = {
                id: id,
                status: RequestStatus.ERROR
            };
        }
    }
    

    @action
    async getUser2(id): Promise<UserModel | undefined> {
        try {
            var userResult = await this.UserService.getByUserId(id);
            // this.UserList = [userResult?.data];
            return userResult?.data;
        }
        catch (error) {
            console.log("Errror: " + JSON.stringify(error))
        }
    }

    @action
    async setUserForNav(id) {
        try {
            var user = await this.UserService.getByUserId(id);
            this.SelectedUser = user?.data;
        }
        catch (error) {
            console.log("Error: " + JSON.stringify(error))
        }
    }

    @action
    async resetSelectedUser() {
        this.SelectedUser = undefined;
    }

    @action
    async refreshCurrentUser() {
        if (this.CurrentUser.id && !(this.CurrentUserRequest.id == this.CurrentUser.id && this.CurrentUserRequest.status == RequestStatus.ACTIVE)) {
            this.CurrentUserRequest.id = this.CurrentUser.id
            this.CurrentUserRequest.status = RequestStatus.ACTIVE;
            var result = await this.getUser(this.CurrentUser.id);
            if (result) {
                this.CurrentUser = result;
                this.CurrentUserRequest.status = RequestStatus.COMPLETED;
            } else {
                this.CurrentUserRequest.status = RequestStatus.ERROR;
            }
        }
    }



    @action
    async resetCurrentUser() {
        this.CurrentUser = {};
    }

    @action
    async logout() {
        try {
            var logoutResult = await this.AccountService.logoutCreate();
            this.CurrentUser = {};
            this.UserList = [];
        }
        catch (error) {
            console.log("Error: " + JSON.stringify(error))
        }
    }
}

let userStore: UserStore;

export function GetUserStore() {
    if (!userStore) {
        userStore = new UserStore({}, [], [], [], { id: 0, status: RequestStatus.PENDING }, undefined, { id: 0, status: RequestStatus.PENDING });
    }
    return userStore;
}