import { lazy } from 'react';
import { DEFAULT_PATHS } from 'config.js';

const NotFound = lazy(() => import('views/default/NotFound'));
// const Home = lazy(() => import('views/default/Home'));
const Contact = lazy(() => import('views/default/Contact'));
const Login = lazy(() => import('views/default/Login'));
const ForgotPassword = lazy(() => import('views/default/ForgotPassword'));
const Register = lazy(() => import('views/default/Register'));
const GetStarted = lazy(() => import('views/default/GetStarted'));
const Pricing = lazy(() => import('views/default/Pricing'));
const ResetPassword = lazy(() => import('views/default/ResetPassword'));
const ResetYourPassword = lazy(() => import('views/default/ResetYourPassword'));
const Unauthorized = lazy(() => import('views/default/Unauthorized'));
const ComingSoon = lazy(() => import('views/default/ComingSoon'));
const InvalidAccess = lazy(() => import('views/default/InvalidAccess'));
const App = lazy(() => import('App.js'));
const Learn = lazy(() => import('views/home/components/LandingPage'));


/*
{ path: "/path", exact: true, component: ViewHome },
// or
{ path: "/path", component: ViewHome},
// or
{ path: "/path", exact: true, redirect: true, to: "/redirectPath" },
*/
const defaultRoutes = [
  { path: DEFAULT_PATHS.NOTFOUND, exact: true, component: NotFound },
  { path: DEFAULT_PATHS.LOGIN, exact: true, component: Login },
  // { path: DEFAULT_PATHS.HOME, exact: true, component: Home },
  { path: DEFAULT_PATHS.REGISTER, exact: true, component: Register },
  { path: DEFAULT_PATHS.COMINGSOON, exact: true, component: ComingSoon },
  { path: DEFAULT_PATHS.FORGOT_PASSWORD, exact: true, component: ForgotPassword },
  { path: DEFAULT_PATHS.RESET_PASSWORD, exact: true, component: ResetYourPassword },
  { path: DEFAULT_PATHS.GET_STARTED, exact: true, component: GetStarted },
  { path: DEFAULT_PATHS.PRICING, exact: true, component: Pricing },
  { path: DEFAULT_PATHS.UNAUTHORIZED, exact: true, component: Unauthorized },
  { path: DEFAULT_PATHS.UNAUTHORIZED + "/:id/:name", exact: true, component: Unauthorized },
  { path: DEFAULT_PATHS.INVALID_ACCESS, exact: true, component: InvalidAccess },
  { path: DEFAULT_PATHS.CONTACT, exact: true, component: Contact },
  { path: DEFAULT_PATHS.APP, component: App },
  { path: DEFAULT_PATHS.LEARN, component: Learn },
];

export default defaultRoutes;
