/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BooleanResponseWrapper, EventBaseModel, EventModel, EventModelIEnumerableResponseWrapper, EventModelResponseWrapper } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Event<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags EventContoller
   * @name GetEventById
   * @request GET:/Event/{eventId}
   */
  getEventById = (eventId: number, params: RequestParams = {}) =>
    this.request<EventModelResponseWrapper, any>({
      path: `/Event/${eventId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name GetAllFutureEvents
   * @request GET:/Event/GetFuture
   */
  getAllFutureEvents = (params: RequestParams = {}) =>
    this.request<EventModelIEnumerableResponseWrapper, any>({
      path: `/Event/GetFuture`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name GetFutureFeaturedEvents
   * @request GET:/Event/GetFeatured
   */
  getFutureFeaturedEvents = (params: RequestParams = {}) =>
    this.request<EventModelIEnumerableResponseWrapper, any>({
      path: `/Event/GetFeatured`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name GetTotalFutureEventsCount
   * @request GET:/Event/GetTotalFutureEventsCount
   */
  getTotalFutureEventsCount = (params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/Event/GetTotalFutureEventsCount`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name GetAllFutureEventsSearchFilter
   * @request GET:/Event/GetFutureEventsSearchFilter
   */
  getAllFutureEventsSearchFilter = (
    query?: {
      /**
       * @format int32
       * @default 1
       */
      page?: number;
      /**
       * @format int32
       * @default 10
       */
      pageSize?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<EventModelIEnumerableResponseWrapper, any>({
      path: `/Event/GetFutureEventsSearchFilter`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name GetAllPastEvents
   * @request GET:/Event/GetPast
   */
  getAllPastEvents = (params: RequestParams = {}) =>
    this.request<EventModelIEnumerableResponseWrapper, any>({
      path: `/Event/GetPast`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name GetAllFutureEventsBasicInfo
   * @request GET:/Event/GetFuture/BasicInfo
   */
  getAllFutureEventsBasicInfo = (params: RequestParams = {}) =>
    this.request<EventBaseModel, any>({
      path: `/Event/GetFuture/BasicInfo`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name GetAllFutureTrendingEvents
   * @request GET:/Event/GetTrending
   */
  getAllFutureTrendingEvents = (params: RequestParams = {}) =>
    this.request<EventModelIEnumerableResponseWrapper, any>({
      path: `/Event/GetTrending`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name GetEventsForBusiness
   * @request GET:/Event/GetBusinessEvents
   */
  getEventsForBusiness = (params: RequestParams = {}) =>
    this.request<EventModelIEnumerableResponseWrapper, any>({
      path: `/Event/GetBusinessEvents`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name SaveEvent
   * @request POST:/Event/Save
   */
  saveEvent = (data: EventModel, params: RequestParams = {}) =>
    this.request<EventModelResponseWrapper, any>({
      path: `/Event/Save`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name AddEvent
   * @request POST:/Event
   */
  addEvent = (data: EventBaseModel, params: RequestParams = {}) =>
    this.request<EventModelResponseWrapper, any>({
      path: `/Event`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name PatchEvent
   * @request PATCH:/Event
   */
  patchEvent = (data: EventBaseModel, params: RequestParams = {}) =>
    this.request<EventModelResponseWrapper, any>({
      path: `/Event`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name DeleteEvent
   * @request DELETE:/Event
   */
  deleteEvent = (
    query?: {
      /** @format int32 */
      eventId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/Event`,
      method: 'DELETE',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name GetEventByDirectorId
   * @request GET:/Event/ByDirector/{directorId}
   */
  getEventByDirectorId = (directorId: number, params: RequestParams = {}) =>
    this.request<EventModelIEnumerableResponseWrapper, any>({
      path: `/Event/ByDirector/${directorId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name AssignDirectorUserIdToEventId
   * @request POST:/Event/{eventId}/assign-director/{directorUserId}
   */
  assignDirectorUserIdToEventId = (eventId: number, directorUserId: number, params: RequestParams = {}) =>
    this.request<EventModelResponseWrapper, any>({
      path: `/Event/${eventId}/assign-director/${directorUserId}`,
      method: 'POST',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventContoller
   * @name GetAllEvents
   * @request GET:/Event/Admin/GetAll
   */
  getAllEvents = (params: RequestParams = {}) =>
    this.request<EventModelIEnumerableResponseWrapper, any>({
      path: `/Event/Admin/GetAll`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
