// HtmlHead.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const HtmlHead = ({ title, description, image }) => {
  const defaultTitle = 'HelloTiara';
  const titleTemplate = '%s | HelloTiara';
  const defaultDescription = 'The all-in-one, ultimate pageant management software for the glitz, natural, and scholarship industries created for all roles in pageantry.';
  const defaultImage = 'https://hellotiaraphotostorage.blob.core.windows.net/hello-tiara/HelloTiara-FindPageants.png';
  const location = useLocation();
const url = window.location.origin + location.pathname;

  return (
    <HelmetProvider>
    <Helmet
    defaultTitle={defaultTitle}
    titleTemplate={titleTemplate}
  >
    <title>{title}</title>
    <meta name="description" content={description || defaultDescription} />
    <meta name="image" content={image || defaultImage} />
    <meta property="og:title" content={title || defaultTitle} />
    <meta property="og:description" content={description || defaultDescription} />
    <meta property="og:image" content={image || defaultImage} />
    <meta property="og:keywords" content={'Find Beauty Pageants, Get Started in Pageants, Win Pageants'} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content='website'/>
  </Helmet>
  </HelmetProvider>
  );
};

export default HtmlHead;
