export const DIMENSION = {
  Desktop: 'desktop',
  Tablet: 'tablet',
  Mobile: 'mobile',
};

export const LAYOUT = {
  Fluid: 'fluid',
  Boxed: 'boxed',
};

export const RADIUS = {
  Rounded: 'rounded',
  Standard: 'standard',
  Flat: 'flat',
};

export const THEME_COLOR = {
  LightBlue: 'light-blue',
  DarkBlue: 'dark-blue',
  LightRed: 'light-red',
  DarkRed: 'dark-red',
  LightGreen: 'light-green',
  DarkGreen: 'dark-green',
  LightPurple: 'light-purple',
  DarkPurple: 'dark-purple',
  LightPink: 'light-pink',
  DarkPink: 'dark-pink',
  LightHelloTiara: 'light-hellotiara',
  DarkHelloTiara: 'dark-hellotiara',
};

export const NAV_COLOR = {
  Default: 'default',
  Match: 'match',
  Light: 'light',
  Dark: 'dark',
};
export const NAV_USER = {
  Business: 'business',
  Default: 'default',
};
export const MENU_PLACEMENT = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};
export const EVENT_TYPE = {
  Pageant: 'pageant',
  Other: 'other',
};
export const MENU_BEHAVIOUR = {
  Pinned: 'pinned',
  Unpinned: 'unpinned',
};

export const USER_ROLE = {
  Admin: 'admin',
  User: 'user',
  Editor: 'editor',
};

export const USER_TYPE = {
  Director: 'director',
  Vendor: 'vendor',
  Coach: 'coach',
  Contestant: 'contestant',
  Staff: 'staff',
  Judge: 'judge',
  Viewable: 'viewable',
};

export const USER_RELATIONSHIP = {
  Admin: 'admin',
  Primary: 'primary',
  Secondary: 'secondary',
  Minor: 'minor',
  Staff: 'staff',
};


