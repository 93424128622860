import { PropsWithChildren } from "react";
import { GetUserStore } from "SessionState/User/UserStore";
import { observer } from "mobx-react";


export const BetaWrapper = observer((props: PropsWithChildren) => {
    const userStore = GetUserStore();
    
    if (userStore.CurrentUser?.betaUser) {
        return (<div className="bg-separator beta">{props.children}</div>)    
    }
    return (null)
})

export const ProMemberWrapper = observer((props: PropsWithChildren) => {
    const userStore = GetUserStore();
    
    if (userStore.CurrentUser?.roles?.includes("Director")) {
        return (<div>{props.children}</div>)    
    }
    return (null)
})

export const NotProMemberWrapper = observer((props: PropsWithChildren) => {
    const userStore = GetUserStore();
    
    if (!userStore.CurrentUser?.roles?.includes("Director")) {
        return (<div>{props.children}</div>)    
    }
    return (null)
})