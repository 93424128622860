import { Image } from 'API/Generated/Image';


class ImageStore {
    ImageService: Image = new Image();



    constructor() {

    }

    async uploadImage(file: { FileName?: string; File?: File }, containerName: string): Promise<any> {
        try {
            const result = await this.ImageService.uploadCreate(
                { FileName: file.FileName, File: file.File },
                { containerName }
            );
            console.log("Result", result);
            return result.data.data;
        } catch (error) {
            console.log("Error:" + JSON.stringify(error));
            return null;
        }
    }

    async getImage(id: string, containerName: string): Promise<string> {
        try {
            if (id.includes("data:image/")) {
                return id;
            }
            if (id === '') {
                return '/img/profile/imagePlaceholder_1.png';
            }
            
            var result = await this.ImageService.retrieveDetail(id, containerName );
            var blob = await result.blob();
            var url = new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    resolve(e.target?.result as string);
                };
                reader.onerror = (e) => {
                    reject(e);
                };
                reader.readAsDataURL(blob);
            });
            
            return url;
        } catch (error) {
            console.log("Error:" + JSON.stringify(error));
            return '/img/profile/imagePlaceholder_1.png';
        }
    }
    
}

let imageStore: ImageStore;

export function GetImageStore(): ImageStore {
    if (!imageStore) {
        imageStore = new ImageStore();
    }
    return imageStore;
}