import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { messages } from './messages/messages';

const LangProvider = ({ children }) => {
  const { currentLang } = useSelector((state) => state.lang);
  //Added empty onError to avoid console error when translation is not found
  return (
    <IntlProvider locale={currentLang.locale} messages={messages[currentLang.locale]} onError={() => {}}>
      {children}
    </IntlProvider>
  );
};
export default LangProvider;
