export enum UserRole {
    Admin = "Admin",
    Director = "Director",
    Judge = "Judge",
    Contestant = "Contestant",
    Guest = "Guest",
    User = "Basic",
    Vendor = "Vendor",
    Coach = "Coach",
    Restricted = "Restricted",
    MiniDirectorMember = "MiniDirectorMember",
    GrandDirectorMember = "GrandDirectorMember"
}
