/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddUserToBusinessRequest,
  BooleanResponseWrapper,
  BusinessUserBaseModel,
  BusinessUserModel,
  BusinessUserModelIEnumerableResponseWrapper,
  BusinessUserModelResponseWrapper,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class BusinessUsers<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags BusinessUser
   * @name GetByBusinessUserId
   * @request GET:/BusinessUsers/{businessUserId}
   */
  getByBusinessUserId = (businessUserId: number, params: RequestParams = {}) =>
    this.request<BusinessUserModel, any>({
      path: `/BusinessUsers/${businessUserId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags BusinessUser
   * @name GetAllBusinessUsersForBusiness
   * @request GET:/BusinessUsers/GetAllBusinessUsersForBusiness/{businessId}
   */
  getAllBusinessUsersForBusiness = (businessId: number, params: RequestParams = {}) =>
    this.request<BusinessUserModelIEnumerableResponseWrapper, any>({
      path: `/BusinessUsers/GetAllBusinessUsersForBusiness/${businessId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags BusinessUser
   * @name SaveBusinessUser
   * @request POST:/BusinessUsers/Save
   */
  saveBusinessUser = (data: BusinessUserModel, params: RequestParams = {}) =>
    this.request<BusinessUserModelResponseWrapper, any>({
      path: `/BusinessUsers/Save`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags BusinessUser
   * @name AddBusinessUser
   * @request POST:/BusinessUsers
   */
  addBusinessUser = (data: BusinessUserBaseModel, params: RequestParams = {}) =>
    this.request<BusinessUserModelResponseWrapper, any>({
      path: `/BusinessUsers`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags BusinessUser
   * @name PatchBusinessUser
   * @request PATCH:/BusinessUsers
   */
  patchBusinessUser = (data: BusinessUserBaseModel, params: RequestParams = {}) =>
    this.request<BusinessUserModelResponseWrapper, any>({
      path: `/BusinessUsers`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags BusinessUser
   * @name DeleteBusinessUser
   * @request DELETE:/BusinessUsers
   */
  deleteBusinessUser = (
    query?: {
      /** @format int32 */
      businessUserId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/BusinessUsers`,
      method: 'DELETE',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags BusinessUser
   * @name AddUserToBusiness
   * @request POST:/BusinessUsers/AddToBusiness
   */
  addUserToBusiness = (data: AddUserToBusinessRequest, params: RequestParams = {}) =>
    this.request<BusinessUserModelResponseWrapper, any>({
      path: `/BusinessUsers/AddToBusiness`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
