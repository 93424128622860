// cra imports
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals.js';


// import redux requirements
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { store, persistedStore } from 'store.js';


// import html head tags requirements
import { HelmetProvider } from 'react-helmet-async';

// import multi language
import LangProvider from 'lang/LangProvider';

// import routing modules
import { BrowserRouter as Router } from 'react-router-dom';
import RouteIdentifier from 'routing/components/RouteIdentifier';
import Loading from 'components/loading/Loading';

// import routes
import { getLayoutlessRoutes } from 'routing/helper';
import defaultRoutes from 'routing/default-routes';
import routesAndMenuItems from 'routes.js';

// import toastify for notification
import { Slide, ToastContainer } from 'react-toastify';

// mock server register for demo
// import '@mock-api';

import { configurePersistable } from 'mobx-persist-store';
import { GetRootStore } from 'SessionState/RootStore';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import HtmlHead from 'components/html-head/HtmlHead';

const Main = () => {
   // Create an instance of your MobX RootStore
 const rootStore = GetRootStore();

  //if (!new class { x }().hasOwnProperty('x')) throw new Error('Transpiler is not configured correctly'); // Mobx requirement for Typescript
  const layoutlessRoutes = useMemo(() => getLayoutlessRoutes({ data: routesAndMenuItems }), []);
  return (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistedStore}>
        {/* <HelmetProvider> */}
          <ToastContainer transition={Slide} newestOnTop />
          <Router basename={process.env.REACT_APP_BASENAME}>
            <LangProvider>
              <RouteIdentifier routes={[...layoutlessRoutes, ...defaultRoutes]} fallback={<Loading />} />
            </LangProvider>
          </Router>
          {/* </HelmetProvider> */}
        </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
