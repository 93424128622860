/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BooleanResponseWrapper, ContestantUserBaseModel, ContestantUserModel, ContestantUserModelResponseWrapper } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ContestantUsers<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ContestantUser
   * @name GetByContestantUserId
   * @request GET:/ContestantUsers/{contestantUserId}
   */
  getByContestantUserId = (contestantUserId: number, params: RequestParams = {}) =>
    this.request<ContestantUserModel, any>({
      path: `/ContestantUsers/${contestantUserId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ContestantUser
   * @name GetAllContestantUsers
   * @request GET:/ContestantUsers
   */
  getAllContestantUsers = (params: RequestParams = {}) =>
    this.request<ContestantUserBaseModel[], any>({
      path: `/ContestantUsers`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ContestantUser
   * @name AddContestantUser
   * @request POST:/ContestantUsers
   */
  addContestantUser = (data: ContestantUserBaseModel, params: RequestParams = {}) =>
    this.request<ContestantUserModelResponseWrapper, any>({
      path: `/ContestantUsers`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ContestantUser
   * @name PatchContestantUser
   * @request PATCH:/ContestantUsers
   */
  patchContestantUser = (data: ContestantUserBaseModel, params: RequestParams = {}) =>
    this.request<ContestantUserModelResponseWrapper, any>({
      path: `/ContestantUsers`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ContestantUser
   * @name DeleteContestantUser
   * @request DELETE:/ContestantUsers
   */
  deleteContestantUser = (
    query?: {
      /** @format int32 */
      contestantUserId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/ContestantUsers`,
      method: 'DELETE',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ContestantUser
   * @name SaveContestantUser
   * @request POST:/ContestantUsers/Save
   */
  saveContestantUser = (data: ContestantUserModel, params: RequestParams = {}) =>
    this.request<ContestantUserModelResponseWrapper, any>({
      path: `/ContestantUsers/Save`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
