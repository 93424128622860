import { IndustryStyleTypes } from "API/Generated/IndustryStyleTypes";
import { Pageant } from "API/Generated/Pageant";
import { PageantRegionalTypes } from "API/Generated/PageantRegionalTypes";
import { IndustryStyleTypeModel, PageantAvailableTitleModel, PageantModel, PageantRegionalTypeBaseModel, PageantRegistrationRequest, RegisteredPageantContestantModel } from "API/Generated/data-contracts";
import { makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { RequestStatus } from "SessionState/RequestHandling/RequestStatus";
import { PageantRegistration } from "API/Generated/PageantRegistration";

interface Request {
    id: number;
    status: RequestStatus;
}

class PageantStore {
    PageantService: Pageant = new Pageant();
    PageantRegionalTypesService: PageantRegionalTypes = new PageantRegionalTypes();
    IndustryStyleTypeService: IndustryStyleTypes = new IndustryStyleTypes();
    rules: string = '';
    details: string = '';
    PageantRegistration: PageantRegistration = new PageantRegistration();

    CurrentPageant: PageantModel;
    CurrentPageantRequest: Request = {
        id: 0,
        status: RequestStatus.PENDING
    };
    PageantRegionalTypeList: PageantRegionalTypeBaseModel[];
    IndustryStyleTypeList: IndustryStyleTypeModel[];

    ShowTitleForm: boolean = false;
    AllowEdit: boolean = true;

    constructor(currentPageant: PageantModel, pageantRegionalTypeList: PageantRegionalTypeBaseModel[], industryStyleTypeList: IndustryStyleTypeModel[], currentPageantRequest: Request) {
        makeObservable(this, {
            CurrentPageant: observable,
            PageantRegionalTypeList: observable,
            IndustryStyleTypeList: observable,
            CurrentPageantRequest: observable,
        })
        // makePersistable(this, { name: 'PageantStore', properties: ['CurrentPageant', 'PageantRegionalTypeList', 'IndustryStyleTypeList']});
        this.CurrentPageant = currentPageant;
        this.PageantRegionalTypeList = pageantRegionalTypeList;
        this.IndustryStyleTypeList = industryStyleTypeList;
        this.CurrentPageantRequest = currentPageantRequest;
    }

    setRules(rules: string) {
        this.rules = rules;
      }

      setDetails(details: string) {
        this.details = details;
      }

    async getPageant(id: number, reset:boolean = false) {
        try {
            if (this.CurrentPageantRequest.id == id && 
                this.CurrentPageantRequest.status == RequestStatus.ACTIVE || this.CurrentPageantRequest.status == RequestStatus.COMPLETED
                && !reset) {
                return;
            }
            this.CurrentPageantRequest = { id: id, status: RequestStatus.ACTIVE };
            var pageantResult = await this.PageantService.getPageantById(id)
            // console.log("data", pageantResult);
            if (pageantResult.data.data) {
                this.CurrentPageantRequest.status = RequestStatus.COMPLETED;
                this.CurrentPageant = pageantResult?.data.data;
            }
        } catch (error) {
            this.CurrentPageantRequest.status = RequestStatus.ERROR;
            console.log("Error:" + JSON.stringify(error));
        }
    }
    async savePageant(model: PageantModel): Promise<boolean> {
        try {
            var pageantResult = await this.PageantService.addPageant(model);
            console.log("PageantResult", pageantResult);

            if (pageantResult.data.data) {
                this.CurrentPageant = pageantResult?.data.data;
                return true;
            }
            return false;
        } catch (error) {
            console.log("Error: " + JSON.stringify(error));
            return false;
        }
    }
    async savePageantSchedule(schedule: string): Promise<boolean> {
        try {
            var pageantToUpdate = { ...this.CurrentPageant, schedule: schedule }
            var pageantResult = await this.PageantService.addPageant(pageantToUpdate)

            if (pageantResult.data.data) {
                this.CurrentPageant = pageantResult?.data.data;
                return true;
            }
            return false;
        } catch (error) {
            console.log("Error: " + JSON.stringify(error));
            return false;
        }
    }

    async getPageantRegionalTypes() {
        try {
            if (this.PageantRegionalTypeList.length == 0) {
                var pageantRegionalTypeResult = await this.PageantRegionalTypesService.getPageantRegionalTypes()
                if (pageantRegionalTypeResult.data.data) {
                    this.PageantRegionalTypeList = pageantRegionalTypeResult.data.data
                }
            }
        } catch (error) {
            console.log("Error: " + JSON.stringify(error));
        }
    }

    async getIndustryStyleTypes() {
        try {
            if (this.IndustryStyleTypeList.length == 0) {
                var industryStyleTypeResult = await this.IndustryStyleTypeService.getAllIndustryStyleTypes()
                if (industryStyleTypeResult.data) {
                    this.IndustryStyleTypeList = industryStyleTypeResult.data
                }
            }
        } catch (error) {
            console.log("Error: " + JSON.stringify(error));
        }
    }

    async refreshCurrentPageant() {
        if (this.CurrentPageant.id) {
            await this.getPageant(this.CurrentPageant.id, true);
        }
    }

    async getRegistration(id: number): Promise<RegisteredPageantContestantModel | undefined> {
        try {
            const registrationResult = await this.PageantRegistration.getPageantRegistrationById(id);
            return registrationResult?.data?.data;
        } catch (error) {
            console.log("Error: " + JSON.stringify(error));
        }
    }
    
    async refreshCurrentRegistration(Request: PageantRegistrationRequest) {
        const registeredContestants = this.CurrentPageant?.registeredPageantContestants ?? [];
        const registeredContestant = registeredContestants.find(x => x.id == Request?.pageantRegistrationModel?.id);
    
        if (registeredContestant?.id) {
            const result = await this.getRegistration(registeredContestant.id);
            if (result) {
                const index = registeredContestants.indexOf(registeredContestant);
                if (index !== -1) {
                    registeredContestants[index] = result;
                }
            }
        }
    }
    
    


    

    async resetCurrentPageant() {
        // this.CurrentPageant = {};
        this.CurrentPageantRequest = { id: 0, status: RequestStatus.PENDING };
    }

    async showTitleForm() {
        this.ShowTitleForm = true;
    }
    async hideTitleForm() {
        this.ShowTitleForm = false;
    }
    async setAllowEdit(allowEdit: boolean) {
        this.AllowEdit = allowEdit;
    }
    async updatePageantAward(item: PageantAvailableTitleModel){
        if (this.CurrentPageant.pageantAvailableTitles != undefined) {
            this.CurrentPageant.pageantAvailableTitles = this.CurrentPageant.pageantAvailableTitles?.filter(x => x.id != item.id)
            this.CurrentPageant.pageantAvailableTitles = [...this.CurrentPageant.pageantAvailableTitles, item]    
        }
    }

}

let pageantStore: PageantStore;

export function GetPageantStore() {
    if (!pageantStore) {
        pageantStore = new PageantStore({}, [], [], { id: 0, status: RequestStatus.PENDING });
    }
    return pageantStore;
}