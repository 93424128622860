import { createSlice } from '@reduxjs/toolkit';
import { SERVICE_URL } from 'config.js';
import axios from 'axios';

const initialState = {
  staff: [],
  loading: false,
  showSettings: { deleted: false, status: 'All', tags: 'All' },
  setSelectedStaff: null,
};

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    receiveService(state, action) {
      const { staff, loading } = action.payload;
      state.staff = staff;
      state.loading = loading;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setShowSettings(state, action) {
      state.showSettings = action.payload;
    },
    setSelectedStaff(state, action) {
      state.selectedStaff = action.payload;
    },
  },
});
const { setLoading, receiveService } = staffSlice.actions;

export const { setSelectedStaff, setShowSettings } = staffSlice.actions;

export const getStaff = () => async (dispatch) => {
  dispatch(setLoading(true));
  const response = await axios.get(`${SERVICE_URL}/events/staff`);
  const staff = response.data;
  dispatch(receiveService({ staff, loading: false }));
};

export const createStaff =
  ({ item }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    console.log('...create...item', item);

    const response = await axios.post(`${SERVICE_URL}/events/staff`, { item });
    const staff = response.data;
    dispatch(receiveService({ staff, loading: false }));
  };

export const updateStaff =
  ({ item }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const response = await axios.put(`${SERVICE_URL}/events/staff`, { item });
    const staff = response.data;
    dispatch(receiveService({ staff, loading: false }));
  };
export const deleteStaff =
  ({ id }) =>
  async (dispatch) => {
    const response = await axios.delete(`${SERVICE_URL}/events/staff`, { id });
    const staff = response.data;
    dispatch(receiveService({ staff, loading: false }));
  };

const staffReducer = staffSlice.reducer;

export default staffReducer;
