/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { StateModel } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Geography<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Geography
   * @name GetByStateId
   * @request GET:/Geography/States/{stateId}
   */
  getByStateId = (stateId: number, params: RequestParams = {}) =>
    this.request<StateModel, any>({
      path: `/Geography/States/${stateId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Geography
   * @name GetStates
   * @request GET:/Geography/States
   */
  getStates = (params: RequestParams = {}) =>
    this.request<StateModel[], any>({
      path: `/Geography/States`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
