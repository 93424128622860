/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BooleanResponseWrapper,
  BusinessBaseModel,
  BusinessModel,
  BusinessModelIEnumerableResponseWrapper,
  BusinessModelResponseWrapper,
  BusinessTypeModel,
  ProductServiceCategoryModel,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Businesses<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Business
   * @name GetByBusinessId
   * @request GET:/Businesses/{businessId}
   */
  getByBusinessId = (businessId: number, params: RequestParams = {}) =>
    this.request<BusinessModel, any>({
      path: `/Businesses/${businessId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Business
   * @name GetAllActiveBusinesses
   * @request GET:/Businesses/GetActive
   */
  getAllActiveBusinesses = (params: RequestParams = {}) =>
    this.request<BusinessModelIEnumerableResponseWrapper, any>({
      path: `/Businesses/GetActive`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Business
   * @name SaveBusiness
   * @request POST:/Businesses/Save
   */
  saveBusiness = (data: BusinessModel, params: RequestParams = {}) =>
    this.request<BusinessModelResponseWrapper, any>({
      path: `/Businesses/Save`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Business
   * @name AddBusiness
   * @request POST:/Businesses
   */
  addBusiness = (data: BusinessBaseModel, params: RequestParams = {}) =>
    this.request<BusinessModelResponseWrapper, any>({
      path: `/Businesses`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Business
   * @name PatchBusiness
   * @request PATCH:/Businesses
   */
  patchBusiness = (data: BusinessBaseModel, params: RequestParams = {}) =>
    this.request<BusinessModelResponseWrapper, any>({
      path: `/Businesses`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Business
   * @name DeleteBusiness
   * @request DELETE:/Businesses
   */
  deleteBusiness = (
    query?: {
      /** @format int32 */
      businessId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/Businesses`,
      method: 'DELETE',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Business
   * @name GetAllBusinessTypes
   * @request GET:/Businesses/Types
   */
  getAllBusinessTypes = (params: RequestParams = {}) =>
    this.request<BusinessTypeModel[], any>({
      path: `/Businesses/Types`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Business
   * @name GetCategories
   * @request GET:/Businesses/Categories
   */
  getCategories = (params: RequestParams = {}) =>
    this.request<ProductServiceCategoryModel[], any>({
      path: `/Businesses/Categories`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Business
   * @name GetCategoriesByBusinessId
   * @request GET:/Businesses/Categories/{businessId}
   */
  getCategoriesByBusinessId = (businessId: number, params: RequestParams = {}) =>
    this.request<ProductServiceCategoryModel[], any>({
      path: `/Businesses/Categories/${businessId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
