import { makeAutoObservable, action } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { GetUserRegistrationStore } from '../SessionState/Registration/UserRegistrationStore';
import { GetEventStore } from '../SessionState/Event/EventStore';
import { GetUserStore } from '../SessionState/User/UserStore';
import { GetPageantStore } from '../SessionState/Pageant/PageantStore';
import { GetBusinessStore } from '../SessionState/Business/BusinessStore';
import { GetContestantStore } from '../SessionState/Contestant/ContestantStore';
import { GetGeoStore } from './Geo/GeoStore';
import { GetPageantRegistrationStore } from './Pageant/PageantRegistrationStore';
import { GetPaymentStore } from './Payment/PaymentStore';
import { GetImageStore } from './Image/ImageStore';
import { GetPageantAwardStore } from './Pageant/PageantAwardStore';


class RootStore {
  eventStore: ReturnType<typeof GetEventStore>;
  userStore: ReturnType<typeof GetUserStore>;
  pageantStore: ReturnType<typeof GetPageantStore>;
  userRegistrationStore: ReturnType<typeof GetUserRegistrationStore>;
  businessStore: ReturnType<typeof GetBusinessStore>;
  contestantStore: ReturnType<typeof GetContestantStore>;
  geoStore: ReturnType<typeof GetGeoStore>;
  pageantRegistrationStore: ReturnType<typeof GetPageantRegistrationStore>;
  paymentStore: ReturnType<typeof GetPaymentStore>;
  imageStore: ReturnType<typeof GetImageStore>;
  // awardStore: ReturnType<typeof GetAwardStore>;

  pageTitle = 'Hello Tiara Root';
  pageDescription = 'The Virtual Pageant Network';
  pageImage = 'https://example.com/default-event-image.jpg';

  constructor() {
    makeAutoObservable(this);

    // Initialize your stores
    this.eventStore = GetEventStore();
    this.userStore = GetUserStore();
    this.pageantStore = GetPageantStore();
    this.userRegistrationStore = GetUserRegistrationStore();
    this.businessStore = GetBusinessStore();
    this.contestantStore = GetContestantStore();
    this.geoStore = GetGeoStore();
    this.pageantRegistrationStore = GetPageantRegistrationStore();
    this.paymentStore = GetPaymentStore();
    this.imageStore = GetImageStore();
    // this.awardStore = GetPageantAwardStore();

    // Make the entire root store persistable
    makePersistable(this, {
      storage: sessionStorage,
      properties: ['eventStore', 'userStore', 'pageantStore', 'userRegistrationStore', 'businessStore', 'contestantStore', 'geoStore', 'pageantRegistrationStore', 'paymentStore', 'imageStore'],
      name: ''
    });

    // console.log('Calling RootStore');
  }

  @action updateMetaTags(title: string, description: string, image: string) {
    this.pageTitle = title;
    this.pageDescription = description;
    this.pageImage = image;
  }

   /**
   * Check if a specific action is available in a store.
   * @param storeName The name of the store to check.
   * @param actionName The name of the action to check.
   * @returns True if the action is available, false otherwise.
   */
  isActionAvailable(storeName: keyof RootStore, actionName: string): boolean {
    const store = this[storeName];
    return !!store && typeof store[actionName] === 'function';
  }

}

// const rootStore = new RootStore();
// export default rootStore;

let rootStore: RootStore;

export function GetRootStore() {
    if (!rootStore) {
      rootStore = new RootStore();
    }
    return rootStore;
}
