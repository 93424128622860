import { createSlice } from '@reduxjs/toolkit';
import { SERVICE_URL } from 'config.js';
import axios from 'axios';

const initialState = {
  items: [],
  loading: false,
  selectedNote: null,
  currentMode: 'note', // chat - call
  selectedTab: 'messages',
  currentCall: null,
};

const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    receiveService(state, action) {
      const { items, loading } = action.payload;
      state.items = items;
      state.loading = loading;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    chatSetSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    chatSetSelectedChat(state, action) {
      if (action.payload && action.payload.messages && action.payload.messages.length > 0) {
        state.selectedTab = 'messages';
      }
      state.selectedNote = action.payload;
    },
    chatSetCurrentCall(state, action) {
      state.currentCall = action.payload;
    },
    chatChangeMode(state, action) {
      state.currentMode = action.payload;
    },
  },
});
const { setLoading, receiveService } = noteSlice.actions;

export const { chatChangeMode, chatSetSelectedChat, chatSetSelectedTab, chatSetCurrentCall } = noteSlice.actions;

export const getItems = () => async (dispatch, getState) => {
  const state = getState();
  dispatch(setLoading(true));
  const response = await axios.get(`${SERVICE_URL}/events/chat`);
  const items = response.data;
  dispatch(receiveService({ items, loading: false }));
  if (state.chat.selectedNote === null) dispatch(chatSetSelectedChat(items.filter((x) => x.messages.length > 0)[0]));
};

export const addTextToChat =
  ({ note, text }) =>
  async (dispatch) => {
    if (text !== '' && text.length > 0) {
      dispatch(setLoading(true));
      const response = await axios.put(`${SERVICE_URL}/events/chat/addText`, { id: note.id, text });
      const items = response.data;
      dispatch(receiveService({ items, loading: false }));
      dispatch(chatSetSelectedChat(items.filter((x) => x.id === note.id)[0]));
    }
  };

export const addAttachmentsToChat =
  ({ note, attachments }) =>
  async (dispatch) => {
    if (attachments !== null && attachments.length > 0) {
      dispatch(setLoading(true));
      const response = await axios.put(`${SERVICE_URL}/events/chat/addAttachments`, { id: note.id, attachments });
      const items = response.data;
      dispatch(receiveService({ items, loading: false }));
      dispatch(chatSetSelectedChat(items.filter((x) => x.id === note.id)[0]));
    }
  };

export const selectNote =
  ({ note }) =>
  async (dispatch) => {
    if (note !== null) {
      dispatch(setLoading(true));
      const response = await axios.put(`${SERVICE_URL}/events/chat/read`, { id: note.id });
      const items = response.data;
      dispatch(receiveService({ items, loading: false }));
      dispatch(chatSetSelectedChat(items.filter((x) => x.id === note.id)[0]));
    } else {
      dispatch(chatSetSelectedChat(null));
    }
  };

export const createChat =
  ({ item }) =>
  async (dispatch) => {
    dispatch(setLoading(true));

    const response = await axios.post(`${SERVICE_URL}/events/chat`, { item });
    const items = response.data;
    dispatch(receiveService({ items, loading: false }));
  };

export const updateChat =
  ({ item }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const response = await axios.put(`${SERVICE_URL}/events/chat`, { item });
    const items = response.data;
    dispatch(receiveService({ items, loading: false }));
  };

export const deleteChat =
  ({ ids }) =>
  async (dispatch) => {
    const response = await axios.delete(`${SERVICE_URL}/events/chat`, { ids });
    const items = response.data;
    dispatch(receiveService({ items, loading: false }));
  };

const noteReducer = noteSlice.reducer;

export default noteReducer;
