import { ContestantUsers } from "API/Generated/ContestantUsers";
import { ContestantUsersBasic } from "API/Generated/ContestantUsersBasic";
import { ContestantPublicInfoBaseModel, ContestantUserBaseModel, PictureModel, RegisteredContestantPublicInfo } from "API/Generated/data-contracts";
import { ContestantUserModel } from "API/Generated/data-contracts";
import { RequestStatus } from "SessionState/RequestHandling/RequestStatus";
import { makeObservable, observable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";

interface Request {
    id: number;
    status: RequestStatus;
    firstName: string | null | undefined;
    lastName: string | null | undefined;
    birthday: string | null | undefined;
}



class ContestantStore {
    ContestantService: ContestantUsers = new ContestantUsers();
    ContestantServiceBasic: ContestantUsersBasic = new ContestantUsersBasic();
    CurrentContestant: ContestantUserModel;
    ContestantListBasic: ContestantUserBaseModel[] = [];
    ContestantListPublicInfo: ContestantPublicInfoBaseModel[] = [];
    RecentTitleWinners: RegisteredContestantPublicInfo[] = [];
    ContestantList: ContestantUserModel[];
    CurrentContestantRequest: Request = {
        id: 0,
        status: RequestStatus.PENDING,
        firstName: "",
        lastName: "",
        birthday: "",
    };
    CurrentContestantPublicRequest: Request = {
        id: 0,
        status: RequestStatus.PENDING,
        firstName: "",
        lastName: "",
        birthday: "",
    };


    constructor(currentContestant: ContestantUserModel, contestantList: ContestantUserModel[], contestantListBasic: ContestantUserBaseModel[], contestantListPublicInfo: ContestantPublicInfoBaseModel[], currentContestantRequest: Request, currentContestantPublicRequest: Request ) {
        makeObservable(this, {
            CurrentContestant: observable,
            ContestantList: observable,
            ContestantListBasic: observable,
            ContestantListPublicInfo: observable,
            CurrentContestantRequest: observable,
            CurrentContestantPublicRequest: observable,
        })
        // makePersistable(this, {name: 'ContestantStore', properties: ['CurrentContestant', 'ContestantList', 'CurrentContestantRequest']});
        this.CurrentContestant = currentContestant;
        this.ContestantList = contestantList;
        this.ContestantListBasic = contestantListBasic;
        this.ContestantListPublicInfo = contestantListPublicInfo;
        this.CurrentContestantRequest = currentContestantRequest;
        this.CurrentContestantPublicRequest = currentContestantPublicRequest;
    }

    async saveContestant(model: ContestantUserModel): Promise<boolean>{
        try {
            if ((this.CurrentContestantRequest.id == model.id || (this.CurrentContestantRequest.firstName == model.firstName && this.CurrentContestantRequest.lastName == model.lastName && this.CurrentContestantRequest.birthday == model.birthday)) 
            && (this.CurrentContestantRequest.status == RequestStatus.ACTIVE || this.CurrentContestantRequest.status == RequestStatus.COMPLETED)
                ) {
                return false;
                };
            this.resetCurrentContestant()
            this.CurrentContestantRequest = { id: model.id ?? 0, status: RequestStatus.ACTIVE, firstName: model.firstName, lastName: model.lastName, birthday: model.birthday };
            var contestantResult = await this.ContestantService.saveContestantUser(model);
            if (contestantResult.data.data) {
                this.CurrentContestantRequest.status = RequestStatus.COMPLETED;
                this.CurrentContestant = contestantResult?.data.data;    
                return true;
            }
            return false;
        } catch (error){
            this.CurrentContestantRequest.status = RequestStatus.ERROR;
            console.log("Error: " + JSON.stringify(error));
            return false;
        }
    }
    
    // async getContestant(id: number, reset:boolean = false) {
    //     try {
    //         if (this.CurrentContestantRequest.id == id && 
    //             (this.CurrentContestantRequest.status == RequestStatus.ACTIVE || this.CurrentContestantRequest.status == RequestStatus.COMPLETED)
    //             && !reset) {
    //             return;
    //         }
    //         this.resetCurrentContestant()
    //         this.CurrentContestantRequest = { id: id, status: RequestStatus.ACTIVE, firstName: this.CurrentContestant.firstName, lastName: this.CurrentContestant.lastName, birthday: this.CurrentContestant.birthday };
    //         var contestantResult = await this.ContestantService.getByContestantUserId(id)
    //         console.log("data", contestantResult);
    //         if (contestantResult.data) {
    //             this.CurrentContestantRequest.status = RequestStatus.COMPLETED;
    //             this.CurrentContestant = contestantResult?.data;
    //         }
    //     } catch (error) {
    //         this.CurrentContestantRequest.status = RequestStatus.ERROR;
    //         console.log("Error:" + JSON.stringify(error));
    //     }
    // }

    async getContestant(id: number, reset: boolean = false): Promise<ContestantUserModel | undefined> {
        try {
            if (this.CurrentContestantRequest.id === id && 
                (this.CurrentContestantRequest.status === RequestStatus.ACTIVE || this.CurrentContestantRequest.status === RequestStatus.COMPLETED)
                && !reset) {
                return this.CurrentContestant; // Return the current contestant if already fetched
            }
            this.resetCurrentContestant();
            this.CurrentContestantRequest = { id: id, status: RequestStatus.ACTIVE, firstName: this.CurrentContestant.firstName, lastName: this.CurrentContestant.lastName, birthday: this.CurrentContestant.birthday };
            var contestantResult = await this.ContestantService.getByContestantUserId(id);
            console.log("API response:", contestantResult); // Log the API response
            if (contestantResult.data) {
                this.CurrentContestantRequest.status = RequestStatus.COMPLETED;
                this.CurrentContestant = contestantResult.data;
                return this.CurrentContestant; // Return the fetched contestant
            }
        } catch (error) {
            this.CurrentContestantRequest.status = RequestStatus.ERROR;
            console.log("Error:" + JSON.stringify(error));
        }
        return undefined; // Return undefined in case of error
    }

    async getContestants() {
        try {
            var contestantResult = await this.ContestantService.getAllContestantUsers();
            this.ContestantList = contestantResult?.data;
        }
        catch (error) {
            console.log("Errror: " + JSON.stringify(error))
        }
    }

    async getContestantsPublicInfo(reset = false) {

        if (this.CurrentContestantPublicRequest.status === RequestStatus.ACTIVE ||
            this.CurrentContestantPublicRequest.status === RequestStatus.COMPLETED && !reset) {
          return;
        }
    
        this.CurrentContestantPublicRequest.status = RequestStatus.ACTIVE;
    
        try {
          const contestantResult = await this.ContestantServiceBasic.getAllContestantUsersBasicPublicInfo();
    
          if (contestantResult?.data) {
            runInAction(() => {
              this.ContestantListPublicInfo = contestantResult.data
                .filter(contestant => contestant.userId != null && !contestant.accountPrivate)
                .map(contestant => ({
                  id: contestant.id ?? 0,
                  firstName: contestant.firstName ?? "",
                  lastName: contestant.lastName ?? "",
                  hairColor: contestant.hairColor ?? "",
                  eyeColor: contestant.eyeColor ?? "",
                  profilePic: contestant.profilePic ? {
                    id: contestant.profilePic.id ?? 0,
                    image: contestant.profilePic.image ?? "",
                    imageName: contestant.profilePic.imageName ?? "",
                    imageType: contestant.profilePic.imageType ?? ""
                  } : undefined,
                  userId: contestant.userId ?? 0,
                  city: contestant.city ?? "",
                  stateId: contestant.stateId ?? 0,
                  accountPrivate: contestant.accountPrivate ?? false,
                  titleCount: contestant.titleCount ?? 0,
                }));
    
              this.CurrentContestantPublicRequest.status = RequestStatus.COMPLETED;
            //   this.ContestantListBasic = contestantResult?.data;
            });
          }
        } catch (error) {
          runInAction(() => {
            this.CurrentContestantPublicRequest.status = RequestStatus.ERROR;
          });
          console.log("Error fetching contestants' public info: " + JSON.stringify(error));
        }
      }

    async getContestantPublicInfo(id: number, reset:boolean = false) {
        try {
            if (this.CurrentContestantPublicRequest.id == id && 
                (this.CurrentContestantPublicRequest.status == RequestStatus.ACTIVE || this.CurrentContestantPublicRequest.status == RequestStatus.COMPLETED)
                && !reset) {
                return;
            }
            this.resetCurrentContestant()
            this.CurrentContestantPublicRequest = { id: id, status: RequestStatus.ACTIVE, firstName: this.CurrentContestant.firstName, lastName: this.CurrentContestant.lastName, birthday: this.CurrentContestant.birthday };
            var contestantResult = await this.ContestantServiceBasic.getContestantUserPublicInfoByContestantId(id)
            console.log("data", contestantResult);
            if (contestantResult.data) {
                this.CurrentContestantPublicRequest.status = RequestStatus.COMPLETED;
                this.CurrentContestant = contestantResult?.data;
            }
        } catch (error) {
            this.CurrentContestantPublicRequest.status = RequestStatus.ERROR;
            console.log("Error:" + JSON.stringify(error));
        }
    }

    async getRecentTitleWinners() {
        try {
          const recentWinnersResult = await this.ContestantServiceBasic.getAllRecentWinnersBasicPublicInfo();
          if (recentWinnersResult?.data) {
            this.RecentTitleWinners = recentWinnersResult.data
              .filter(contestant => contestant.userId != null && !contestant.accountPrivate)
              .flatMap(contestant =>
                contestant.registeredContestants
                  ?.filter(rc => rc.wonTitles && rc.wonTitles.length > 0)
                  .map(rc => ({
                    registrationId: rc.registrationId,
                    ageGroupId: rc.pageantAgeGroup?.id,
                    headShot: rc.headShot ? {
                      id: rc.headShot.id ?? 0,
                      image: rc.headShot.image ?? "",
                      imageName: rc.headShot.imageName ?? "",
                      imageType: rc.headShot.imageType ?? ""
                    } : undefined,
                    wonTitles: rc.wonTitles ?? [],
                    pageant: rc.pageant
                  }))
                  .filter(Boolean) as RegisteredContestantPublicInfo[]
              );
          }
        } catch (error) {
          console.log("Error fetching recent title winners: " + JSON.stringify(error));
        }
      }


    async refreshCurrentContestant() {
        try {
          
            var contestantResult = await this.ContestantService.getByContestantUserId(this.CurrentContestantRequest.id);
            runInAction(() => {
                console.log("Refreshing Contestant: ", contestantResult);
                this.CurrentContestant = contestantResult?.data || {};
            });
        }
        catch (error) {
            // TODO: Call Log API endpoint
            console.log("Error: " + JSON.stringify(error));
        }
        // if (this.CurrentContestant.id !== undefined && this.CurrentContestant.id !== null) {
        //    await this.getContestant(this.CurrentContestant.id);
           
        // }
    }


    async resetCurrentContestant() {
        this.CurrentContestant = {};
    }
    async saveImage(image: PictureModel): Promise<boolean> {
        try {
            console.log("BeforeRequest");
            
            let currentContestant = this.CurrentContestant;
            console.log("SetImage");
            currentContestant.profilePic = {...image};
            console.log("Attempt Request");
            let apiResult = await this.ContestantService.saveContestantUser(currentContestant);
            if (apiResult.data) {
                console.log("requestResponse", apiResult.data);
                if (apiResult.data.isSuccessful) {
                    return true;
                }
                // return apiResult.data.exception!!;
                return false;
            }
            return false;
        }
        catch (error) {
            console.log("Error in Store");
            console.log(`Error: ${JSON.stringify(error)}`);
            return false;
        }
    }

    async saveContestantSettings(contestant: ContestantUserBaseModel): Promise<boolean> {
        try {
            console.log("BeforeRequest");
            
            let currentContestant = {...this.CurrentContestant};
            currentContestant.firstName = contestant.firstName;
            currentContestant.lastName = contestant.lastName;
            currentContestant.hairColor = contestant.hairColor;
            currentContestant.eyeColor = contestant.eyeColor;
            currentContestant.city = contestant.city;
            currentContestant.stateId = contestant.stateId;
            currentContestant.hobbies = contestant.hobbies;
            currentContestant.profilePic = contestant.profilePic;
            currentContestant.registeredPageantContestants = undefined;
            currentContestant.birthday = contestant.birthday;
            currentContestant.accountPrivate = contestant.accountPrivate;
            
            let apiResult = await this.ContestantService.saveContestantUser(currentContestant);
            if (apiResult.data) {
                console.log("requestResponse", apiResult.data);
                if (apiResult.data.isSuccessful) {
                    return true;
                }
                // return apiResult.data.exception!!;
                return false;
            }
            return false;
        }
        catch (error) {
            console.log("Error in Store");
            console.log(`Error: ${JSON.stringify(error)}`);
            return false;
        }
    }
}

let contestantStore: ContestantStore;

export function GetContestantStore() {
    if (!contestantStore) {
        contestantStore = new ContestantStore({}, [], [], [], {id: 0, status: RequestStatus.PENDING, firstName: "", lastName: "", birthday: "" }, {id: 0, status: RequestStatus.PENDING, firstName: "", lastName: "", birthday: "" });
    }
    return contestantStore;
}