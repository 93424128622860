/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BooleanResponseWrapper, PageantModel, PageantModelResponseWrapper, UserBaseModel } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Pageant<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Pageant
   * @name GetPageantById
   * @request GET:/Pageant/{pageantId}
   */
  getPageantById = (pageantId: number, params: RequestParams = {}) =>
    this.request<PageantModelResponseWrapper, any>({
      path: `/Pageant/${pageantId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Pageant
   * @name DeletePageant
   * @request DELETE:/Pageant/{pageantId}
   */
  deletePageant = (pageantId: number, params: RequestParams = {}) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/Pageant/${pageantId}`,
      method: 'DELETE',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Pageant
   * @name AddPageant
   * @request POST:/Pageant
   */
  addPageant = (data: PageantModel, params: RequestParams = {}) =>
    this.request<PageantModelResponseWrapper, any>({
      path: `/Pageant`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Pageant
   * @name PatchPageant
   * @request PATCH:/Pageant
   */
  patchPageant = (data: PageantModel, params: RequestParams = {}) =>
    this.request<PageantModelResponseWrapper, any>({
      path: `/Pageant`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Pageant
   * @name TestAutoMapper
   * @request GET:/Pageant/TestAutoMapper
   */
  testAutoMapper = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/Pageant/TestAutoMapper`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name GetUsersByPageant
   * @request GET:/Pageant/{pageantId}/Users
   */
  getUsersByPageant = (pageantId: number, params: RequestParams = {}) =>
    this.request<UserBaseModel[], any>({
      path: `/Pageant/${pageantId}/Users`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
