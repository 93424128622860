import { makeAutoObservable } from "mobx"
import { makePersistable } from 'mobx-persist-store';
import { AddUserToBusinessRequest, BusinessBaseModel, BusinessModel, BusinessTypeModel, BusinessUserBaseModel, BusinessUserModel, LogModel, PictureModel } from '../../API/Generated/data-contracts'
import { Businesses } from '../../API/Generated/Businesses'
import { Logs } from "API/Generated/Logs";
import { makeObservable, observable } from "mobx";
import { BusinessType } from "API/Generated/BusinessType";
import { BusinessUsers } from "API/Generated/BusinessUsers";
import { User } from "oidc-client";
import { RequestStatus } from "SessionState/RequestHandling/RequestStatus";

interface Request {
    id: number;
    status: RequestStatus;
}

export class BusinessStore {

    // Services
    BusinessService: Businesses = new Businesses();
    BusinessTypesService: BusinessType = new BusinessType();
    BusinessUserService: BusinessUsers = new BusinessUsers();
    Logger: Logs = new Logs();
    CurrentBusiness: BusinessModel;
    CurrentBusinessRequest: Request = {
        id: 0,
        status: RequestStatus.PENDING
    };
    AllActiveBusinessesList: BusinessModel[];
    ActiveBusinessesRequest: Request = {
        id: 0,
        status: RequestStatus.PENDING
    }
    AllBusinessUsersForBusiness: BusinessUserModel[];


    ShowModal: boolean = false;
    AllowEdit: boolean = false;

    // Properties
    BusinessTypes: BusinessTypeModel[];

    // Constructors
    constructor(currentBusiness: BusinessModel, 
        businessTypes: BusinessTypeModel[], 
        allActiveBusinessesList: BusinessModel[], 
        allBusinessUsersForBusiness: BusinessUserModel[], 
        currentBusinessRequest: Request, 
        activeBusinessesRequest: Request) {
        makeAutoObservable(this, {
            CurrentBusiness: observable,
            BusinessTypes: observable,
            ShowModal: observable,
            AllowEdit: observable,
            AllActiveBusinessesList: observable,
            AllBusinessUsersForBusiness: observable,
            CurrentBusinessRequest: observable
        });
        // makePersistable(this, { name: 'BusinessStore', properties: ['CurrentBusiness', 'BusinessTypes', 'AllActiveBusinessesList', 'AllBusinessUsersForBusiness'] });
        this.BusinessTypes = businessTypes;
        this.CurrentBusiness = currentBusiness;
        this.AllActiveBusinessesList = allActiveBusinessesList;
        this.AllBusinessUsersForBusiness = allBusinessUsersForBusiness;
        this.ActiveBusinessesRequest = activeBusinessesRequest;
        this.CurrentBusinessRequest = currentBusinessRequest;
    }

    // async getBusinessTypes() {
    //     try {
    //         var businessTypeResult = await this.BusinessTypesService.getAllBusinessTypes();
    //         if (businessTypeResult.data.data) {
    //             this.BusinessTypes = businessTypeResult?.data.data;
    //         }
    //     }
    //     catch (error) {
    //         console.log("Errror: " + JSON.stringify(error))
    //     }
    // }


    // Actions
    //TODO: Delete? This feels like it breaks the pattern, there is no need to store BusinessTypes in the store.
    async getAllBusinessTypes() : Promise<BusinessTypeModel[]>
    {
        try{
            let result = await this.BusinessService.getAllBusinessTypes();
            this.BusinessTypes = result.data || this.BusinessTypes;
            return result.data;
        }
        catch(error){
            //TODO: Call Log API endpoint
            console.log(`Error: ${JSON.stringify(error)}`);
        }
        return [];
    }

    
 
    async getBusiness(id: number, reset:boolean = false) {
        try {
            if (this.CurrentBusinessRequest.id == id && 
                this.CurrentBusinessRequest.status == RequestStatus.ACTIVE || this.CurrentBusinessRequest.status == RequestStatus.COMPLETED
                && !reset) {
                return;
            }
            this.CurrentBusinessRequest = { id: id, status: RequestStatus.ACTIVE };
            var businessResult = await this.BusinessService.getByBusinessId(id)
            console.log("data", businessResult);
            if (businessResult.data) {
                this.CurrentBusinessRequest.status = RequestStatus.COMPLETED;
                this.CurrentBusiness = businessResult?.data;
            }
        } catch (error) {
            this.CurrentBusinessRequest.status = RequestStatus.ERROR;
            console.log("Error:" + JSON.stringify(error));
        }

        // try{
        //     var businessResult = await this.BusinessService.getByBusinessId(id);
        //     this.CurrentBusiness = businessResult?.data;
        // }
        // catch(error){
        //     console.log("Errror: " + JSON.stringify(error))
        // }
    }

    // async getActiveBusinesses() {
    //     try {
    //         var businessResult = await this.BusinessService.getAllActiveBusinesses()
    //         if (businessResult.data.data) {
    //             this.AllActiveBusinessesList = businessResult.data.data
    //         }
    //     }
    //     catch (error) {
    //         console.log("Error: " + JSON.stringify(error));
    //     }
    // }

    async getActiveBusinesses(reset:boolean = false) {
        try {
            if (this.ActiveBusinessesRequest.status == RequestStatus.ACTIVE || this.ActiveBusinessesRequest.status == RequestStatus.COMPLETED
                && !reset) {
                return;
            }
            this.ActiveBusinessesRequest = {id: 0, status: RequestStatus.ACTIVE };
            var activeBusinessesResult = await this.BusinessService.getAllActiveBusinesses()
            // console.log("data", pageantResult);
            if (activeBusinessesResult.data.data) {
                this.ActiveBusinessesRequest.status = RequestStatus.COMPLETED;
                this.AllActiveBusinessesList = activeBusinessesResult?.data.data;
            }
        } catch (error) {
            this.ActiveBusinessesRequest.status = RequestStatus.ERROR;
            console.log("Error:" + JSON.stringify(error));
        }
    }

    async getAllBusinessUsersForBusiness(businessId: number) {
        try {
            var businessUsersResult = await this.BusinessUserService.getAllBusinessUsersForBusiness(businessId)
         
            if (businessUsersResult.data.data) {
                this.AllBusinessUsersForBusiness = businessUsersResult.data.data
            }
        }
        catch (error) {
            console.log("Error: " + JSON.stringify(error));
        }
    }

    async refreshCurrentBusiness() {
        if (this.CurrentBusiness.id) {
            await this.getBusiness(this.CurrentBusiness.id);
        }
    }
    async resetCurrentBusiness() {
        this.CurrentBusinessRequest = { id: 0, status: RequestStatus.PENDING };
    }

    async addBusiness(model: BusinessBaseModel) : Promise<string>{
        try{
            let result = await this.BusinessService.addBusiness(model);
            if(result.data){
                if(result.data.isSuccessful){
                    return "Business added successfully!";
                }
                return result.data.exception!!;
            }
        }
        catch(error){
            //TODO: Call Log API endpoint
            console.log(`Error: ${JSON.stringify(error, null, 2)}`);
            return "Error adding Business.";
        }
        return "Error adding Business.";
    }

    // async addBusinessUserByEmail(email: string): Promise<string> {
    //     try {
    //       // Fetch the user by email
    //       let user = await this.BusinessUserService.ge(email);
    
    //       if (user) {
    //         // Create a new BusinessUserBaseModel based on the obtained user
    //         const businessUserModel: BusinessUserBaseModel = {
    //           userId: user.,
    //           // other properties based on your BusinessUserBaseModel
    //         };
    
    //         // Call the existing addBusinessUser method with the new model
    //         const result = await this.addBusinessUser(businessUserModel);
    
    //         return result;
    //       } else {
    //         return 'User not found with the provided email.';
    //       }
    //     } catch (error) {
    //       // TODO: Call Log API endpoint
    //       console.log(`Error: ${JSON.stringify(error, null, 2)}`);
    //       return 'Error adding Business.';
    //     }
    //   }

    async addBusinessUserByEmail(model: AddUserToBusinessRequest): Promise<string> {
        try {
          let result = await this.BusinessUserService.addUserToBusiness(model);
    
          if (result.data) {
            if (result.data.isSuccessful) {
              // Update the local state with the new business user
              return 'Business User added successfully!';
            }
            return result.data.exception!!;
          }
        } catch (error) {
          // TODO: Call Log API endpoint
          console.log(`Error: ${JSON.stringify(error, null, 2)}`);
          return 'Error adding Business.';
        }
        return 'Error adding Business.';
      }

    
      // The existing addBusinessUser method
      async addBusinessUser(model: BusinessUserBaseModel): Promise<string> {
        try {
          let result = await this.BusinessUserService.addBusinessUser(model);
    
          if (result.data) {
            if (result.data.isSuccessful) {
              // Update the local state with the new business user
              businessStore.addBusinessUser(model);
    
              return 'Business User added successfully!';
            }
            return result.data.exception!!;
          }
        } catch (error) {
          // TODO: Call Log API endpoint
          console.log(`Error: ${JSON.stringify(error, null, 2)}`);
          return 'Error adding Business.';
        }
        return 'Error adding Business.';
      }

    async updateBusiness(business: BusinessModel) {
        try {
      
            let apiResult = await this.BusinessService.saveBusiness(business);

            if (apiResult.data) {
                if (apiResult.data.isSuccessful) {
                    return "Business updated successfully!";
                }
                return apiResult.data.exception!!;
            }
        }
        catch (error) {
            console.log(`Error: ${JSON.stringify(error)}`);
            return "Error updating business.";
        }
    }

    async updateBusinessProfile(business: BusinessModel) {
        try {
            let currentBusiness = {...this.CurrentBusiness};
            currentBusiness.name = business.name;
            currentBusiness.isActive = business.isActive;
            currentBusiness.startDate = business.startDate;
            currentBusiness.businessLogo = business.businessLogo;
            currentBusiness.businessType = business.businessType;
            currentBusiness.about = business.about;
            currentBusiness.website = business.website;
            currentBusiness.city = business.city;
            currentBusiness.stateId = business.stateId;
       
            let apiResult = await this.BusinessService.saveBusiness(currentBusiness);
            if (apiResult.data) {
                if (apiResult.data.isSuccessful) {
                    this.refreshCurrentBusiness();
                    return "Business updated successfully!";

                }
                return apiResult.data.exception!!;
            }
        }
        catch (error) {
            console.log(`Error: ${JSON.stringify(error)}`);
            return "Error updating user.";
        }
    }

    async deleteBusiness(id: number): Promise<boolean> {
        try {
            console.log("deleteAward", id);
            var businessResult = await this.BusinessService.deleteBusiness();
            if (businessResult.data.data) {
                return true;
            }
            return false;
        } catch (error) {
            console.log("Error: " + JSON.stringify(error));
            return false;
        }
    }

    async setShowModal(show: boolean) {
        console.log("setShowModal", show);
        this.ShowModal = show;
    }

    async setAllowEdit(allow: boolean) {
        this.AllowEdit = allow;
    }

    async saveImage(image: PictureModel): Promise<boolean> {
        try {
            console.log("BeforeRequest");
            
            let currentBusiness = this.CurrentBusiness;
            console.log("SetImage");
            currentBusiness.businessLogo = {...image};
            console.log("Attempt Request");
            let apiResult = await this.BusinessService.saveBusiness(currentBusiness);
            if (apiResult.data) {
                console.log("requestResponse", apiResult.data);
                if (apiResult.data.isSuccessful) {
                    return true;
                }
                // return apiResult.data.exception!!;
                return false;
            }
            return false;
        }
        catch (error) {
            console.log("Error in Store");
            console.log(`Error: ${JSON.stringify(error)}`);
            return false;
        }
    }
}

let businessStore: BusinessStore;

export function GetBusinessStore(){
    if (!businessStore){
        businessStore = new BusinessStore({}, [], [], [], { id: 0, status: RequestStatus.PENDING }, { id: 0, status: RequestStatus.PENDING });
    }
    return businessStore;
}

//TODO: Find a better place for these!!
//region Types

//endregion