/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { StringResponseWrapper } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Image<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Image
   * @name UploadCreate
   * @request POST:/Image/upload
   */
  uploadCreate = (
    data: {
      FileName?: string;
      /** @format binary */
      File?: File;
    },
    query?: {
      containerName?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<StringResponseWrapper, any>({
      path: `/Image/upload`,
      method: 'POST',
      query: query,
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Image
   * @name RetrieveDetail
   * @request GET:/Image/retrieve/{filepath}/{containerName}
   */
  retrieveDetail = (filepath: string, containerName: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Image/retrieve/${filepath}/${containerName}`,
      method: 'GET',
      ...params,
    });
}
